
import { Vue, Component } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'

@Component({
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class Base extends Vue {
  detail = {}
  loading = true

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.assestsManage.selectAssetByAssetId, {
      assetId: this.$route.params.deviceId
    }).then((res) => {
      this.detail = res || {}
    }).finally(() => {
      this.loading = false
    })
  }
}
